import React, { useEffect, useState } from 'react';
import './App.css';
import Sdr from "./Sdr"


function App() {
  return (
    <div className="App">
        <Sdr/>
    </div>
  );
}

export default App;
